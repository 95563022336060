
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import AppNotification from "@/components/AppNotification.vue";
import { CompareFav } from "@/mixins/compare-fav.mixin";
import { School, SchoolSearchCriteria } from "@/models/interface";
import { metaData } from "@/assets/js/common-meta-info";
import { setMetaInfo } from "@/assets/js/meta-info-config";
import { ProfileType, profileTypeArray } from "@/models/schoolfinder.enums";
import CommonMixin from "@/mixins/common.mixin";
import GoogleMapMixin from "../mixins/google-map.mixin";

@Component({
  components: { AppNotification },
  computed: {
    ...mapState("app", [
      "school",
      "schoolSearchCriteria",
      "mobileView",
      "tabletView",
    ]),
  },
})
export default class CompareList extends Mixins(
  GoogleMapMixin,
  CompareFav,
  CommonMixin
) {
  private compareList: School[] = [];
  ProfileType = ProfileType;
  profileTypeArray = profileTypeArray;
  mobileView!: boolean;
  tabletView!: boolean;
  schoolSearchCriteria!: SchoolSearchCriteria;

  mounted() {
    setMetaInfo({ title: "Compare List" });
    this.compareList = this.getObjectFromSessionStorage("compareList");
    this.compareList = this.compareList.sort((x, y) => {
      return x.id - y.id;
    });
    console.log(this.compareList);
  }

  get resultCardContainerBorder() {
    return {
      "border-right-1": this.tabletView,
      "border-0": this.mobileView,
    };
  }

  get resultCardK12ECLayout() {
    return {
      col: this.tabletView,
      "d-flex flex-row": this.mobileView,
    };
  }
  get roadMilesLayout() {
    return {
      "ml-auto": this.tabletView,
      "": this.mobileView,
    };
  }

  k12Both(school: School) {
    return (
      school.profileType === this.profileTypeArray[this.ProfileType.K12].name ||
      school.profileType === this.profileTypeArray[this.ProfileType.Both].name
    );
  }

  k12(school: School) {
    return (
      school.profileType === this.profileTypeArray[this.ProfileType.K12].name
    );
  }

  earlyChildhood(school: School) {
    return (
      school.profileType ===
      this.profileTypeArray[this.ProfileType.EarlyChildhood].name
    );
  }

  both(school: School) {
    return (
      school.profileType === this.profileTypeArray[this.ProfileType.Both].name
    );
  }

  removeCompare(school: School) {
    this.compareList.forEach((value, index) => {
      if (value.entityId == school.entityId) this.compareList.splice(index, 1);
    });

    this.removeFromCompareList(school);
  }

  addCompareToFavList(school: School) {
    this.compareList.filter(
      (s) => s.entityId == school.entityId
    )[0].isFavorite = true;
    this.addToFavList(school, school.schoolIndex);
  }

  removeCompareFromFavList(school: School) {
    this.compareList.filter(
      (s) => s.entityId == school.entityId
    )[0].isFavorite = false;
    this.removeFromFavList(school);
  }

  navigateToSchoolDetail(school: School, schoolIndex: number) {
    let url;
    if (this.earlyChildhood(school))
      url = "/schools/" + school.entityId + "/ec-school-about/" + school.name;
    else
      url = "/schools/" + school.entityId + "/about-our-school/" + school.name;

    console.log(url);
    school.schoolIndex = schoolIndex;
    sessionStorage.setItem("selected-school", JSON.stringify(school));
    this.$router.push(url);
  }

  navigateToSearchScreen() {
    if (this.schoolSearchCriteria) {
      if (
        this.schoolSearchCriteria.latitude &&
        this.schoolSearchCriteria.longitude
      ) {
        return this.$router.push("/searchresults");
      }
    }
    return this.$router.push("/");
  }

  navigateToCompare() {
    const url = `/compareschools`;
    this.$router.push(url);
  }

  get schoolHeaderContainerLayout() {
    return {
      "mb-3": this.tabletView,
      "": this.mobileView,
    };
  }

  get schoolHeaderMetadataLayout() {
    return {
      "": this.tabletView,
      "flex-column": this.mobileView,
    };
  }
}
